import { MdOutlineDocumentScanner } from 'react-icons/md';
import { RiFolderInfoLine } from 'react-icons/ri';
import { BiLoader, BiLogOut } from 'react-icons/bi';
import { BsShare, BsUpload, BsFillAwardFill } from 'react-icons/bs';
import { CiBank } from 'react-icons/ci';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';

export const ERCMenuItems = [
    {
        title: 'Credit Detail',
        type: 'link',
        to: '/',
        Icon: CiBank,
    },
    {
        title: 'Eligibility',
        type: 'link',
        to: '/qualification-matrix',
        // require: 'metadata.showQualMatrix',
        Icon: AiOutlineSafetyCertificate,
    },
    {
        title: 'Refund Status',
        type: 'link',
        to: '/status',
        Icon: BiLoader,
    },
    {
        title: 'Upload Docs',
        type: 'link',
        to: '/upload',
        Icon: BsUpload,
    },
    {
        title: 'Documents',
        type: 'link',
        to: '/documents',
        Icon: MdOutlineDocumentScanner,
    },
    {
        title: 'My Info',
        type: 'link',
        to: '/info',
        Icon: RiFolderInfoLine,
    },
    {
        title: 'Referral',
        type: 'link',
        to: '/referral',
        Icon: BsShare,
    },
    {
        title: 'Logout',
        type: 'action',
        action: 'logout',
        Icon: BiLogOut,
    },
];
export const SEMenuItems = [
    {
        title: 'Affidavit',
        type: 'link',
        to: '/',
        Icon: BsFillAwardFill,
    },
    {
        title: 'Credit Details',
        type: 'link',
        to: '/credit',
        Icon: CiBank,
    },
    {
        title: 'Upload Docs',
        type: 'link',
        to: '/upload',
        Icon: BsUpload,
    },
    {
        title: 'My Info',
        type: 'link',
        to: '/info',
        Icon: RiFolderInfoLine,
    },
    {
        title: 'Referral',
        type: 'link',
        to: '/referral',
        Icon: BsShare,
    },
    {
        title: 'Logout',
        type: 'action',
        action: 'logout',
        Icon: BiLogOut,
    },
];
