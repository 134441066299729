import React, { useEffect, useState } from 'react';
// Modules
import AccountRedux from 'redux/actions/account';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
// MUI
import { Box, Container } from '@mui/material';
// Components
import Card from 'components/Card/Card';
import FullscreenLoader from 'components/Widgets/FullscreenLoader';
import DocumentAccordion from 'components/Document/DocumentAccordion/DocumentAccordion';
// Styles
import useStyles from './styles';

export default function SEUpload() {
    const classes = useStyles();
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));

    const [application, setApplication] = useState(null);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const [accordions, setAccordions] = useState([]);

    useEffect(() => {
        setApplication(cloneDeep(account));
        setFiles(cloneDeep(account.documents.uploads));
    }, [account]);

    const handleAccordion = (value) => {
        const _accordions = cloneDeep(accordions);
        const index = _accordions.indexOf(value);
        if (index === -1) _accordions.push(value);
        else _accordions.splice(index, 1);

        setAccordions(_accordions);
    };

    function handleNewFile(fArr, newFiles) {
        if (!fArr) return;
        // name, key, type
        const _files = cloneDeep(files ?? []);
        _files.push(...newFiles);
        setFiles(_files);

        handleSubmit(newFiles);
    }

    async function getPresignedUrl(filename, filetype) {
        const {
            data: { url },
        } = await axios.post(
            `${ApiResource.endpoint}/portal/applications/presigned/${application.model.AccountableId}`,
            {
                filename,
                filetype,
            },
            {
                headers: { 'x-auth': user.authToken },
            },
        );

        return url;
    }

    async function handleSubmit(_files) {
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/portal/applications/files/${application.model.AccountableId}`,
                {
                    uploads: _files,
                },
                {
                    headers: { 'x-auth': user.authToken },
                },
            );

            await dispatch(AccountRedux.set(response.data));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    if (!application) return <FullscreenLoader />;

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Card title="File Uploads">
                    <DocumentAccordion
                        application={application}
                        getPresignedUrl={getPresignedUrl}
                        expanded={accordions.includes(1)}
                        onOpen={() => handleAccordion(1)}
                        initialFiles={files || []}
                        onFilesChanged={(f, n) => handleNewFile(f, n, 'uploads')}
                    />
                </Card>
            </Container>
        </Box>
    );
}
