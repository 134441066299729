import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
// MUI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
// Components
import { Check, Delete, ExpandMore } from '@mui/icons-material';
import FileInput from 'components/inputs/FileInput/FileInput';
// Styles
import useStyles from './styles';
import { AppColors } from 'theme';
import clsx from 'clsx';

export default function DocumentAccordion({
    expanded,
    onOpen,
    title,
    application,
    getPresignedUrl,
    details,
    required,
    applicable,
    initialFiles = [],
    immutable = true,
    onFilesChanged,
    error,
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [files, setFiles] = useState(initialFiles);

    function handleOnUpload(fArr) {
        if (!fArr) return;
        // name, key, type
        const _files = [...cloneDeep(files), ...fArr];
        setFiles(_files);
        onFilesChanged?.(_files, fArr);
    }

    function handleRemoveFile(key) {
        const _files = cloneDeep(files).filter((file) => file.key !== key);
        setFiles(_files);
        onFilesChanged?.(_files);
    }

    return (
        <>
            {details && (
                <Accordion expanded={expanded} onChange={onOpen}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ExpandMore />}
                        sx={{
                            borderBottom: `1px solid ${error ? AppColors.Error : AppColors.primary}`,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                            }}
                        >
                            <Typography variant="font2">
                                {title}
                                {required && <span className="red"> (Required)</span>}
                                {applicable && <span className="yellow"> (If Applicable)</span>}
                            </Typography>
                            <Typography variant="font4" sx={{ fontWeight: '600' }}>
                                Instructions
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>{details}</AccordionDetails>
                </Accordion>
            )}
            <FileInput
                onUpload={handleOnUpload}
                classes={{ dropBox: clsx({ [classes.dropBox]: Boolean(details) }) }}
                appendKey={`applications/${application.id}/`}
                presigned={!!getPresignedUrl}
                onGetPresigned={getPresignedUrl}
            />

            {files.length > 0 && (
                <List>
                    {files.map((file, i) => {
                        return (
                            <ListItem key={`${file.name}-${i}`} className={classes.docListItem}>
                                <ListItemText primary={file.filename} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => !file.immutable && handleRemoveFile(file.key)}>
                                        {immutable ? (
                                            <Check
                                                sx={{
                                                    color: AppColors.Success,
                                                }}
                                            />
                                        ) : (
                                            <Delete />
                                        )}
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </>
    );
}
